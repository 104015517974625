import React from 'react'
import "../scss/NavigationBar.scss"
import { captureEvent } from 'event-service/modules/capture'


function NavigationBar(props) {
    const { siteNav } = props;

    let menu = siteNav.menu.map((item, i) => { // loops through menu items
        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code

            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) { // goes to the lowest level to check for drop downs within drop downs

                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return <a id='nav_link' className="dropDownLinkStatic" key={lowItem.link.linkName} href={lowItem.link.linkPath}>{lowItem.link.linkName}</a>
                    })

                    return (
                        <div className="dropDownLink" key={x}>
                            <div className="noButton">
                                {subItem.link.linkName}
                            </div>
                            <div className="bottomMenu">
                                {lowestMenuLevel}
                            </div>
                        </div>
                    )
                } else {
                    return <a id='nav_link' className="dropDownLinkStatic" key={subItem.link.linkName} href={subItem.link.linkPath}>{subItem.link.linkName}</a>
                }
            })

            return (
                <div key={i} className="dropdown" onMouseLeave={() => closeDropDown(item.link.linkName)} onMouseEnter={() => toggleDropDown(item.link.linkName)}>
                    <a id='nav_link' href={item.link.linkPath} className="dropbtn">{item.link.linkName}</a>
                    <div id={item.link.linkName} role="presentation" className="dropdown-content">
                        {subMenu}
                    </div>
                </div>
            )
        } else {
            return (
                <a id='nav_link' key={item.link.linkName} href={item.link.linkPath} className="dropbtn">{item.link.linkName}</a>
            )
        }
    })

    let mobileMenu = siteNav.menu.map((item, i) => {
        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code

            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) {
                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return <a id='nav_link' className="mobileDropDownLinkContent lowest" key={lowItem.link.linkName} href={lowItem.link.linkPath}>{lowItem.link.linkName}</a>
                    })

                    return (
                        <div key={x}>
                            <div role="presentation" className="mobileDropDownLinkContent" onClick={() => toggleMobileDropDown(item._id + subItem.link.linkName + '-mobile-lowest')}>
                                <div className="noButton">{subItem.link.linkName} <i className="las la-angle-down"></i></div>
                            </div>
                            <div id={item._id + subItem.link.linkName + '-mobile-lowest'} role="presentation" className="mobileContent">
                                {lowestMenuLevel}
                            </div>
                        </div>
                    )
                } else {
                    return <a id='nav_link' className="mobileDropDownLinkContent" key={subItem.link.linkName} href={subItem.link.linkPath}>{subItem.link.linkName}</a>
                }
            })

            return (
                <div key={i} className="dropdown">
                    <button onClick={() => toggleMobileDropDown(item._id + '-mobile')} className="mobileButton fullWidth">
                        {item.link.linkName}
                        <i className="las la-angle-down"></i>
                    </button>
                    <div id={item._id + '-mobile'} role="presentation" className="mobileContent">
                        {subMenu}
                    </div>
                </div>
            )
        } else {
            return (
                <a id='nav_link' key={item.link.linkName} href={item.link.linkPath} className="mobileButton">{item.link.linkName}</a>
            )
        }
    })

    const renderTodayHours = (hoursArray, title, today) => hoursArray.length > 0 && hoursArray[today] && (
        <div className="drop-hourBox"><strong>{title}: </strong>{hoursArray[today].time}</div>
    )

    let topHeader = props.group.dealers.map((dealer, i) => {
        let today = new Date(Date.now()).getDay()

        let dropInfo = (
            <div role="presentation" id={dealer.dealerName + i} className="drop-infoCont drop-content" style={i === props.group.dealers.length - 1 ? { right: 0 } : {}} onMouseLeave={() => toggleDropDown(dealer.dealerName + i)}>
                <div className="drop-flex">
                    <div className="drop-left">
                        <div className="drop-block">
                            <div className='drop-locationText'>OUR LOCATION</div>
                            <div className="drop-subText">{dealer.dealerAddress}</div>
                            <a id='nav_link' href={dealer.directions} className="drop-button">Get Directions</a>
                        </div>
                        <div className="drop-block">
                            <div className='drop-locationText'>VISIT WEBSITE</div>
                            <a id='nav_link' href={dealer.websiteLink} className="drop-website">{dealer.website}</a>
                            <div className='drop-locationText'>CONTACT US</div>
                            <div className="drop-subText"><a href={`tel:${dealer.salesNumber}`} data-department="sales">{dealer.salesNumber}</a></div>
                        </div>
                    </div>
                    <div className="drop-right">
                        <div className="drop-block">
                            <div className='drop-locationText'>TODAY'S HOURS</div>
                            <div style={{ marginBottom: '20px' }}>
                                {renderTodayHours(dealer.salesHours, "Sales", today)}
                                {renderTodayHours(dealer.financeHours, "Finance", today)}
                            </div>
                            <a id='nav_link' href={'/contact?dealer=' + dealer.id} className="drop-button">All Hours</a>
                        </div>
                        <div className="drop-block">
                            <div style={{ marginTop: '20px' }}>
                                {renderTodayHours(dealer.serviceHours, "Service", today)}
                                {renderTodayHours(dealer.partsHours, "Parts", today)}
                                {renderTodayHours(dealer.collisionHours, "Collision center", today)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return (
            <div key={i} className="drop-dealerCont drop-dropdown">
                <button className="drop-dealerName" key={i} onMouseLeave={() => toggleDropDown(dealer.dealerName + i)} onClick={() => toggleDropDown(dealer.dealerName + i)} onMouseEnter={() => toggleDropDown(dealer.dealerName + i)}>{dealer.dealerName}</button>
                {dropInfo}
            </div>
        )
    })

    let topMobileHeader = props.group.dealers.map((dealer, i) => {
        let today = new Date(Date.now()).getDay()

        let dropInfo = (
            <div id={dealer.dealerName} className="drop-infoCont drop-content">
                <div className="drop-flex">
                    <button className="drop-close" onClick={() => toggleDropDown(dealer.dealerName)}>Close</button>
                    <div className="drop-left">
                        <div className="drop-block">
                            <div className='drop-locationText'>OUR LOCATION</div>
                            <div className="drop-subText">{dealer.dealerAddress}</div>
                            <a id='nav_link' href={dealer.directions} className="drop-button">Get Directions</a>
                        </div>
                        <div className="drop-block">
                            <div className='drop-locationText'>VISIT WEBSITE</div>
                            <a id='nav_link' href={dealer.websiteLink} className="drop-website">{dealer.website}</a>
                            <div className='drop-locationText'>CONTACT US</div>
                            <div className="drop-subText"><a href={`tel:${dealer.salesNumber}`} data-department="sales">{dealer.salesNumber}</a></div>
                        </div>
                    </div>
                    <div className="drop-right">
                        <div className="drop-block">
                            <div className='drop-locationText'>TODAY'S HOURS</div>
                            <div style={{ marginBottom: '20px' }}>
                                {renderTodayHours(dealer.salesHours, "Sales", today)}
                                {renderTodayHours(dealer.financeHours, "Finance", today)}
                            </div>
                            <a id='nav_link' href={dealer.websiteLink} className="drop-button">All Hours</a>
                        </div>
                        <div className="drop-block">
                            <div style={{ marginTop: '20px' }}>
                                {renderTodayHours(dealer.serviceHours, "Service", today)}
                                {renderTodayHours(dealer.partsHours, "Parts", today)}
                                {renderTodayHours(dealer.collisionHours, "Collision center", today)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return (
            <div key={i} className="drop-dealerCont drop-dropdown">
                <button className="drop-dealerName" key={i} onClick={() => toggleDropDown(dealer.dealerName)}>{dealer.dealerName}</button>
                {dropInfo}
            </div>
        )
    })

    if (typeof window !== 'undefined') {
        window.onscroll = () => addSticky(document.getElementById("navbar"))
    }

    return (
        <header className='stickyHeader sticky' id="navbar">
            <div className="headerContainer">
                <div className="bgHeader" id="desktopBgHeader">
                    {topHeader}
                </div>
                <div className="margins bottomHeader" id="desktopNav">
                    <div className="logoDealer">
                        <a id='nav_link' className="logoContainer" href="/">
                            <img className="logoImage" src={props.group.groupLogo} alt="dealer logo" />
                        </a>
                    </div>
                    <div className="menuItems">
                        {menu}
                    </div>
                </div>
                <div id="mobileNav">
                    <div className="bgHeader" id="mobileHeader">
                        {topMobileHeader}
                    </div>
                    <div className="mobileNavContainer">
                        <div className="logoDealer">
                            <a id='nav_link' className="logoContainer" href="/">
                                <img className="logoImage" src={props.group.groupLogo} alt="dealer logo" />
                            </a>
                        </div>
                        <div className="hamburgerContainer">
                            <button className="hamburgerButton" onClick={() => toggleNav("mobileDropDown")}>
                                <a id='nav_link' aria-label="Toggle Nav Bar" role="button" className=" burger"
                                    style={{ fontSize: "30px", padding: '10px', cursor: 'pointer' }}></a>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mobileDropdown" id="mobileDropDown">
                    {mobileMenu}
                </div>
            </div>
        </header>
    )
}

function toggleDropDown(id) {
    document.getElementById(id).classList.toggle("show");
}

function toggleNav(id) {
    document.getElementById(id).classList.toggle("showNav");
}

function toggleMobileDropDown(id) {
    document.getElementById(id).classList.toggle("showMobile");
}

function closeDropDown(id) {
    document.getElementById(id).classList.remove("show");
}

function addSticky(nav) {
    if (window.pageYOffset >= nav.offsetTop) {
        nav.classList.add("sticky")
    } else {
        nav.classList.remove("sticky");
    }
}

export default NavigationBar
