import React, { useRef, useState, useCallback } from "react"

export default function useLazyElementLoader () {
    const observable = useRef()
    const [isLoaded, toggleLazyItem] = useState(false)
    const lazyItem = useCallback((node) => {
      if (observable.current) observable.current.disconnect();
      observable.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          toggleLazyItem(true)
        }
      });
      if (node)
        observable.current.observe(node);
    })

    return [lazyItem, isLoaded]
}