import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby";
import { Global } from "@emotion/core"

import NavigationBar from '../components/NavigationBar'
import GlobalCSS from '../components/GlobalCSS'
import Footer from '../components/Footer'
import LayoutHTML from '../components/LayoutHTML'
import SEO  from '../components/SEO'
import GTMTracker from "./gtmTracker/GTMTracker";
import { buildOnPageMetadata } from "event-service/modules/utility";
import { AddASCClickableHandlers, AddASCNavHandlers, exposeCaptureEvent, processStoredEvents, captureEvent, AddASCClickToCallHandlers } from "event-service/modules/capture";
import '../scss/font-definitions.scss'
import {navigate} from "../../.cache/gatsby-browser-entry";

export default function Layout(props) {
    const {seo}= props
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
      if (loaded) {
        exposeCaptureEvent();
        processStoredEvents();
        AddASCNavHandlers();
        AddASCClickableHandlers();
        AddASCClickToCallHandlers();
        captureEvent({
          event: 'asc_pageview',
          error_code: '',
          item_id: document.querySelector('data[id="vin"]')?.value || '',
          item_number: document.querySelector('data[id="stock"]')?.value || '',
          item_price: document.querySelector('data[id="price"]')?.value || '',
          item_condition: document.querySelector('data[id="condition"]')?.value || '',
          item_year: document.querySelector('data[id="year"]')?.value || '',
          item_make: document.querySelector('data[id="make"]')?.value || '',
          item_model: document.querySelector('data[id="model"]')?.value || '',
          item_variant: document.querySelector('data[id="trim"]')?.value || '',
          item_color: document.querySelector('data[id="color"]')?.value || '',
          item_type: document.querySelector('data[id="type"]')?.value || '',
          item_category: document.querySelector('data[id="category"]')?.value || '',
          item_fuel_type: document.querySelector('data[id="fuel"]')?.value || '',
          item_inventory_date: document.querySelector('data[id="date"]')?.value || '',
          item_results: document.querySelector('data[id="results"]')?.value || '',
        }, {
          handlers: ['ASC']
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
            console.log(error);
        });
      }
    }, [loaded]);
    return <StaticQuery
        query={pagesQuery}
        render={data => {
            const siteSettings = JSON.parse(data.siteData.siteData.siteSettings)
            if (!loaded) {
              setLoaded(siteSettings.dealerInfo)
              if (siteSettings.dealerFeedInfo) {
                  buildOnPageMetadata({
                      postalCode: siteSettings.dealerFeedInfo?.postalCode,
                      state: siteSettings.dealerFeedInfo?.state,
                      city: siteSettings.dealerFeedInfo?.city
                  })
              }
          }
            const siteNav = JSON.parse(data.siteData.siteData.siteNav)
            const html = siteSettings.html

            const { theme } = siteSettings
            let primaryFont = '"MonMedium"'
            let secondaryFont = '"MonSemiBold"'
            const rgbaTranslator = (hexCode, opacityValue) => {
              const rVal = parseInt(hexCode.substr(1,2), 16).toString()
              const gVal = parseInt(hexCode.substr(3,2), 16).toString()
              const bVal = parseInt(hexCode.substr(5,2), 16).toString()

              return "rgba(" + rVal +  ", " + gVal + ", " + bVal + ", " + opacityValue + ")"
            }

            const themeStyle = {
              primary: theme.colors.primary,
              secondary: theme.colors.secondary,
              primaryText: theme.colors.text,
              secondaryText: theme.colors.text,//theme.colors.secondarytext,
              primaryAccent: theme.colors.accent1,
              secondaryAccent: theme.colors.accent2,
              primaryFont: primaryFont,
              secondaryFont: secondaryFont,
              primaryTranslucent: rgbaTranslator(theme.colors.primary, '0.7')
            }

            if (typeof window !== 'undefined') {
                window.gatsbyNavigate = navigate;
            }
            return (
                <React.Fragment>
                  { (seo !== undefined) &&<SEO key={"SEO"} title={"Layout"} data={seo}/> }
                  <Global styles={GlobalCSS(siteSettings)} />
                  <style type="text/css">
                    {
                      `:root {
                        --primary-color: ${themeStyle.primary};
                        --secondary-color: ${themeStyle.secondary};
                        --primary-text:  ${themeStyle.primaryText};
                        --secondary-text: ${themeStyle.secondaryText};
                        --primary-accent: ${themeStyle.primaryAccent};
                        --secondary-accent: ${themeStyle.secondaryAccent};
                        --primary-font: ${themeStyle.primaryFont};
                        --secondary-font: ${themeStyle.secondaryFont};
                        --primary-translucent: ${themeStyle.primaryTranslucent};
                      }`
                    }
                  </style>

                  <GTMTracker gtmId={siteSettings.dealerInfo.gtmId}/>

                  <NavigationBar siteNav={siteNav} group={siteSettings.dealerInfo} />
                  <div className="bodyContainer">
                      <LayoutHTML data={html.topHtml} />
                      {props.children}
                      <LayoutHTML data={html.bottomHtml} />
                  </div>
                  <Footer content={siteSettings.Footer} dealerInfo={siteSettings.dealerInfo} />
                </React.Fragment>
            )
        }}
    />
}

const pagesQuery = graphql`
{
  siteData {
      siteData {
      siteSettings
      siteNav
      siteMetadata {
          currentTemplateID
          siteName
      }
      pages
      }
  }
}
`