import React, { useEffect } from "react"

export default function GTMTracker(props) {
  const gtmId = props.gtmId

  useEffect(() => {
    if (window !== undefined && window.document) {
      import("react-gtm-module").then(({ default: tagManager }) => {
        tagManager.initialize({ gtmId })
      })
    }
  })

  return ( 
    <></>
  )
}