import React from "react"
import useLazyElementLoader from "../hooks/useLazyElementLoader"
import '../scss/Footer.scss'
import CookiePolicy from "../components/cookie/CookiePolicy"

function Footer(props) {
    let today = new Date()
    let connect = (
        <div className="singleLink connectLink">CONNECT WITH US</div>
    )

    let links = props.content.links.map((link, x) => {

        let subLinks = link.subLinks.map((subLink, i) => {
            return (
                <div key={i} className="subLinkContainer">
                    <a id="nav_link" href={subLink.linkValue} className="singleSubLink">{subLink.linkName}</a>
                </div>
            )
        })

        return (
            <div key={x} className="singleLinkCont">
                <div className="singleLinkPadding">
                    <a id="nav_link" href={link.linkValue} className="singleLink"><span className="dot"></span> {link.linkName}</a>
                </div>
                {subLinks}
            </div>
        )
    })

    let social = props.content.socialMediaLinks.socialLinksArray.map((social, i) => {
        return (
            <div key={i} className="singleSocialCont">
                <a id="nav_link" href={social.link} className='socialLink'>
                    <img alt={social.name} src={social.icon} className='socialImage' />
                </a>
            </div>
        )
    })

    let bottomLinks = props.content.bottomLinkRow.links.map((link, i) => {
        return (
            <div className="footerBottomLink" key={i}>
                <a id="nav_link" href={link.linkValue} className="footerPrivacyLink">{link.linkName} {props.dealerInfo.manufacturer}</a>
                <div className="footerBullet">•</div>
            </div>
        )
    })

    const [lazyFooter, canRender] = useLazyElementLoader()

    const renderBG = () => (
        <img alt="Footer Image" className="footerBg" src={props.content.footerBackgroundImg} />
    )

    const renderImage = () => (
        <img alt="Footer Logo" className="foot-img" src={props.content.footerLogo} />
    )

    return (
        <footer ref={lazyFooter}>
            <div className="footerContainer">
                <div className="footerFlex">
                    <div className="mainFooter">
                        <div className="topSection">
                            <div className="footerLogoDealer">
                                <a id="nav_link" href="/">
                                    <div className="footerLogo">
                                        <img alt="Footer Logo" className="foot-img" src={props.content.footerLogo} />
                                    </div>
                                </a>
                            </div>
                            <div className="footerDealerAddress">
                                <i className="las la-map-marker footerMarker"></i>
                                <div className="footerDealerAddressText">{props.dealerInfo.dealers[0].dealerAddress}</div>
                            </div>
                        </div>
                        <div className="bottomSection">
                            <div className="footerLinksContainer">
                                {links}
                                <div className="desktopSocial">
                                    {props.content.socialMediaLinks.socialLinksArray.length !== 0 ? connect : null}
                                    <div className="socialContainer">
                                        {social}
                                    </div>
                                </div>
                            </div>
                            <div className="tabletSocial">
                                {props.content.socialMediaLinks.socialLinksArray.length !== 0 ? connect : null}
                                <div className="socialContainer">
                                    {social}
                                </div>
                            </div>
                            <div className="footerLegalContainer">
                                <div className="footerPrivacyContainer">
                                    {bottomLinks}
                                    <div href={props.content.contact} className="footerPrivacyLinkDiv">Copyright ©{today.getFullYear()} {props.dealerInfo.manufacturer} All rights reserved.</div>
                                </div>

                                <CookiePolicy dealerInfo={{termsOfService:"/privacy-policy-and-cookie-policy",privacy:"/privacy-policy-and-cookie-policy"}} CookieLinkText="Manage Cookie Policy" />
                                <div className="madeWithLove">MADE WITH <span style={{ color: 'red' }} aria-label="heart" role="img">❤️</span> BY <a id="nav_link" className="madeWithLove dealerMasters" href="https://dealermasters.com/">DEALER MASTERS</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="backToTopContainer"  onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); }}>
                        <div className="backToTop">
                                BACK TO TOP  <i className="las la-arrow-right"></i>
                            </div>
                    </div>
                    <img alt="Footer Image" className="footerBg" src={props.content.footerBackgroundImg} />
                </div>
            </div>
        </footer>
    )
}

export default Footer