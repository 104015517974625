import { css } from "@emotion/core"

// file all the global css like colors for classes that pull from the json data
const globalCSS = ({ theme }) => css`
  header {
    background-color:  ${theme.colors.secondary};
    color: black
  }
  h1 {
    background-color: ${theme.colors.tertiary}
  }
  body {
    background-color:  ${theme.colors.primary};
    padding: 0;
    margin: 0;
    font-family: sans-serif
  }
  .mobileButton {
    background-color:  ${theme.colors.accent1};
  }

  .mobileDropdown {
    background-color: ${theme.colors.accent1};
  }

  .backToTopContainer {
    background-color: ${theme.colors.accent1};
  }

  .dot {
    border: 3px solid ${theme.colors.accent2};
  }

  .dropbtn:hover,
.dropbtn:focus {
    background-color: ${theme.colors.accent1};
    color: white;
}

  .slideButton:hover {
    background-color:  ${theme.colors.accent2} !important;
  }

  .noButton {
    color: ${theme.colors.accent1}
  }
  .headerContainer {
    border-bottom: 2px solid ${theme.colors.accent1};
  }
  .slideButton:hover {
    background-color:  ${theme.colors.accent2};
}

.underline {
    border-bottom: 2px solid ${theme.colors.accent1};
}

::-webkit-scrollbar-thumb {
    background: ${theme.colors.accent1};
}

.mapBox {
  scrollbar-color: ${theme.colors.accent1} rgb(211, 211, 211);
}

`
export default globalCSS